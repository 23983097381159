import firebase from "firebase";

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyDwT6kaPrdbwhDbcvglRq2KmvsX5KQXz5k',
  authDomain: 'esignpapers.firebaseapp.com',
  databaseURL: 'https://esignpapers.firebaseio.com',
  projectId: 'esignpapers',
  storageBucket: 'esignpapers.appspot.com',
  messagingSenderId: '991535872237'
};

firebase.initializeApp(config);
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
